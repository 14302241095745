import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import LogoutButton from "./LogoutButton";
import "./Menu.css";

const Menu = () => {
  const { isLoggedIn, isAdmin } = useAuth();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Do not show the menu on the login page
  if (!isLoggedIn || location.pathname === "/login") {
    return null;
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when a link is clicked (useful on mobile)
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav>
      <div className="nav-container">
        <div className="logo">Movie Review Club</div>
        <div className="menu-icon" onClick={toggleMenu}>
          {/* Hamburger Icon */}
          <div className={`hamburger ${isMenuOpen ? "open" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={`nav-links ${isMenuOpen ? "active" : ""}`}>
          <Link to="/movies" onClick={handleLinkClick}>
            Movie Cards
          </Link>
          {isLoggedIn && (
            <Link to="/user-details" onClick={handleLinkClick}>
              User Details
            </Link>
          )}
          {isAdmin && (
            <>
              <Link to="/add-movie" onClick={handleLinkClick}>
                Add/Edit Movie
              </Link>
              <Link to="/manage-users" onClick={handleLinkClick}>
                Manage Users
              </Link>
            </>
          )}
          {isLoggedIn && <LogoutButton onClick={handleLinkClick} />}
        </div>
      </div>
    </nav>
  );
};

export default Menu;
