import React, { useState, useEffect } from "react";
import styles from "./StarRating.module.css"; // Import the CSS module

const StarRating = ({ count = 0, onChange, size = 24, readOnly = false }) => {
  const [rating, setRating] = useState(count);

  useEffect(() => {
    setRating(count); // Update local rating if count changes
  }, [count]);

  const handleChange = (newRating, event) => {
    if (readOnly) return; // Do nothing if readOnly is true
    event.stopPropagation(); // Prevents the click from affecting parent elements
    let rect = event.currentTarget.getBoundingClientRect();
    let isHalf = event.clientX - rect.left < rect.width / 2; // Check if the click is on the left half of the star
    let finalRating = isHalf ? newRating - 0.5 : newRating; // Adjust rating accordingly
    setRating(finalRating);
    onChange(finalRating); // Send the updated rating
  };

  const renderStar = (starValue) => {
    let starClass = styles.emptyStar;

    if (rating >= starValue) {
      // Full star
      starClass = styles.fullStar;
    } else if (rating >= starValue - 0.5) {
      // Half star
      starClass = styles.halfStar;
    }

    return (
      <svg
        key={starValue}
        viewBox="0 0 24 24"
        className={`${styles.star} ${starClass}`}
        width={size}
        height={size}
        onClick={(e) => handleChange(starValue, e)}
        style={{ cursor: readOnly ? "default" : "pointer" }}
      >
        <defs>
          <linearGradient id={`halfGradient-${starValue}`}>
            <stop offset="50%" stopColor="gold" />
            <stop offset="50%" stopColor="grey" />
          </linearGradient>
        </defs>
        <polygon
          points="12,2 15.09,8.26 22,9.27 17,14.14 18.18,21.02 12,17.77 
            5.82,21.02 7,14.14 2,9.27 8.91,8.26"
          fill={
            starClass === styles.fullStar
              ? "gold"
              : starClass === styles.halfStar
              ? `url(#halfGradient-${starValue})`
              : "grey"
          }
        />
      </svg>
    );
  };

  return (
    <div className={styles.starRating}>
      {[...Array(5)].map((_, index) => {
        const starValue = index + 1;
        return renderStar(starValue);
      })}
    </div>
  );
};

export default StarRating;
