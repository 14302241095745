import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../contexts/AuthContext";
import styles from "./MovieCard.module.css";
import StarRating from "./StarRating"; // Assuming StarRating is in a separate file

const MovieCard = ({ movie, userRating, onUpdateRating, isAdmin, onRevealStatusChange }) => {
    const { user } = useAuth();
    const [isExpanded, setIsExpanded] = useState(false);
    const [originalCardRect, setOriginalCardRect] = useState(null);
    const [popupStyle, setPopupStyle] = useState({});
    const [isReviewing, setIsReviewing] = useState(false); // New state variable
    const cardRef = useRef(null);

    const handleExpand = (event) => {
        event.stopPropagation();
        const rect = cardRef.current.getBoundingClientRect();
        setOriginalCardRect(rect);
        setPopupStyle({
            position: "fixed",
            top: rect.top + "px",
            left: rect.left + "px",
            width: rect.width + "px",
            height: rect.height + "px",
            opacity: 0,
            transform: "translate(0, 0)",
        });
        setIsExpanded(true);
    };

    useEffect(() => {
        if (isExpanded) {
            // Disable background scroll and prevent content shift
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = `${scrollbarWidth}px`;

            // Fade out the background card
            cardRef.current.classList.add(styles.fadeOut);

            // Trigger the animation after the popup is rendered
            requestAnimationFrame(() => {
                setPopupStyle((prevStyle) => ({
                    ...prevStyle,
                    top: "50%",
                    left: "50%",
                    width: "80vw",
                    height: "80vh",
                    transform: "translate(-50%, -50%)",
                    opacity: 1,
                }));
            });
        } else {
            // Enable background scroll
            document.body.style.overflow = "";
            document.body.style.paddingRight = "";

            // Remove fade-out class
            if (cardRef.current) {
                cardRef.current.classList.remove(styles.fadeOut);
            }
        }
    }, [isExpanded]);

    const handleClosePopup = (event) => {
        event.stopPropagation();
        const rect = originalCardRect;
        // Reset popup position and size to original card
        setPopupStyle((prevStyle) => ({
            ...prevStyle,
            top: rect.top + "px",
            left: rect.left + "px",
            width: rect.width + "px",
            height: rect.height + "px",
            transform: "translate(0, 0)",
            opacity: 0,
        }));
        // Allow the animation to complete before hiding the popup
        setTimeout(() => {
            setIsExpanded(false);
            setIsReviewing(false); // Reset review mode when closing popup
        }, 300); // Match the duration of the transition
    };

    const handleRevealScores = (event) => {
        event.stopPropagation();
        const newStatus = movie.is_revealed ? 0 : 1;
        onRevealStatusChange(movie.id, newStatus);
    };

    const handleRatingChange = (newRating) => {
        if (!user) {
            console.error("No user data available.");
            return;
        }
        onUpdateRating(movie.id, newRating, user.id);
        // After updating the rating, you might want to switch back to the revealed scores
        if (movie.is_revealed) {
            setIsReviewing(false);
        }
    };

    return (
        <>
            {isExpanded && <div className={`${styles.overlay} ${isExpanded ? styles.active : ""}`} onClick={handleClosePopup}></div>}
            <div ref={cardRef} className={`${styles.movieCard} ${isExpanded ? styles.fadeOut : ""}`} onClick={handleExpand} style={{ cursor: "pointer" }}>
                {/* Front of the card */}
                <div className={styles.cardFront}>
                    <img src={movie.poster_url} alt={movie.title} />
                    <h3>{movie.title}</h3>
                    {isAdmin && (
                        <button
                            onClick={(event) => {
                                event.stopPropagation();
                                handleRevealScores(event);
                            }}
                            style={{
                                backgroundColor: movie.is_revealed ? "#dc3545" : "#007BFF",
                            }}
                        >
                            {movie.is_revealed ? "Hide Scores" : "Reveal Scores"}
                        </button>
                    )}
                </div>
            </div>
            {isExpanded && (
                <div className={styles.popup} style={popupStyle} onClick={(e) => e.stopPropagation()}>
                    {/* Back of the card */}
                    <div className={styles.cardBack}>
                        <h3>{movie.title}</h3>
                        <p>{movie.description}</p>
                        {!movie.is_revealed || isReviewing ? (
                            <>
                                <StarRating
                                    count={userRating}
                                    onChange={handleRatingChange}
                                    size={60} // Big stars for scoring side
                                />
                                {movie.is_revealed ? (
                                    <button
                                        className={styles.cancelButton}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsReviewing(false);
                                        }}
                                    >
                                        Back
                                    </button>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <div className={styles.scoresGrid}>
                                    {movie.scores
                                        .sort((a, b) => b.score - a.score || a.real_name.localeCompare(b.real_name))
                                        .map((score) => (
                                            <div key={score.user_id} className={styles.scoreItem}>
                                                <div className={styles.realName}>{score.real_name}</div>
                                                <StarRating
                                                    count={score.score}
                                                    onChange={() => {}}
                                                    size={24} // Smaller stars for revealed scores
                                                    readOnly={true}
                                                />
                                            </div>
                                        ))}
                                </div>
                                <button
                                    className={styles.reviewButton}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsReviewing(true);
                                    }}
                                >
                                    Review Movie
                                </button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default MovieCard;
